import React from 'react';
import { useTheme } from 'styled-components';

import { useEditorContext } from '../../../../../../../contexts/EditorContext';
import initTranslations from '../../../../../../../lib/initTranslations';
import DefaultButton from '../../../../../../design_system/buttons/DefaultButton';
import ColorPickerTrigger from '../../../../../../design_system/buttons/DeprecatedColorPicker/ColorPickerTrigger';
import ColorPicker from '../../../../../../design_system/ColorPicker/ColorPicker';
import ColorPickerInput from '../../../../../../design_system/ColorPicker/ColorPickerInput';
import { HIGHLIGHT_COLORS } from '../../../../../../design_system/ColorPicker/constants';
import { SwatchesWrapper } from '../../../../../../design_system/ColorPicker/Swatches/styles';
import TextSwatch from '../../../../../../design_system/ColorPicker/Swatches/TextSwatch';
import Tooltip from '../../../../../../design_system/display/Tooltip/Tooltip';
import { paletteColorDecoder } from '../../../../../../design_system/helpers';
import useActiveMenuHandler from '../../../../../../design_system/useActiveMenuHandler';
import { useEditorColorHandler } from '../../../../shared/useEditorColorHandler';

const t = initTranslations('editor');

const MENU_ID = 'highlight-text-color-picker';

const HighlighterToolbarButton = () => {
  const { closeMenu } = useActiveMenuHandler({ menuId: MENU_ID });
  const { editor } = useEditorContext();
  const { palettes } = useTheme();

  const highlightColor = editor.getAttributes('highlight').color;
  const decodedColor = paletteColorDecoder(palettes, highlightColor);

  const { handleColorSet } = useEditorColorHandler('highlight', (color) => {
    editor.chain().focus().setHighlight({ color }).run();
  });

  const handleColorChange = (color: string) => {
    handleColorSet(color);
    closeMenu();
  };

  return (
    <>
      <Tooltip
        delayShow={250}
        id='editor-highlight-color-tooltip'
        place='bottom'
        text={t('highlight_color')}
      />
      <div data-for='editor-highlight-color-tooltip' data-tip>
        <ColorPicker
          flyoutId={MENU_ID}
          trigger={
            <ColorPickerTrigger
              ariaLabel={t('aria_label_highlight')}
              buttonId='editor-highlight-text-button'
              color={decodedColor}
              type='highlighter-color'
            />
          }
        >
          <SwatchesWrapper rowCount={8}>
            {HIGHLIGHT_COLORS.map((color) => {
              return (
                <TextSwatch
                  activeColor={decodedColor}
                  color={paletteColorDecoder(palettes, color)}
                  key={color}
                  onClick={() => handleColorChange(color)}
                  variant='highlight-color'
                />
              );
            })}
          </SwatchesWrapper>
          <ColorPickerInput
            color={decodedColor}
            onChange={(color: string) => handleColorChange(color)}
          />
          <DefaultButton
            buttonType='tertiary'
            fullWidth
            iconName='droplet-slash'
            id='editor-highlight-removal-button'
            onClick={() => {
              editor.chain().focus().unsetHighlight().run();
              closeMenu();
            }}
            text={t('remove_highlight')}
          />
        </ColorPicker>
      </div>
    </>
  );
};

export default HighlighterToolbarButton;
