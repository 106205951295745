import styled from 'styled-components';

import { Direction } from '../../../../styled/FlexContainer';
import Scrollbar from '../../../../styled/Scrollbar';
import { deprecated, fontMd1 } from '../../../../styled/TypeSystem';
import { MenuItemType } from './PoppableDropdownMenu';

export const DropdownMenuWrapper = styled.div`
  border: ${({ theme: { constants } }) => constants.borderWidthSm} solid
    ${({ theme: { vars } }) => vars.borderSurface2};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  background: ${({ theme: { vars } }) => vars.foundationSurface1};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
`;

export const DropdownMenuListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 17.2rem; /* to match the old dropdown menu design */
  overflow: clip auto;
  ${Scrollbar};
`;

export const DropdownMenuList = styled.ul<{ direction: Direction; itemType: MenuItemType }>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  padding: ${({ itemType, theme: { constants } }) =>
    `${constants.spacerSm3} ${itemType === 'default' ? constants.spacerSm3 : ''}`};
  margin: 0;
`;

export const DropdownMenuMessage = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme: { vars } }) => vars.foundationBase2};
  padding: ${({ theme: { constants } }) =>
    `${constants.spacerSm3} ${constants.spacerSm3} ${constants.spacerSm3} ${constants.spacerMd3}`};
  cursor: default;
`;

export const DropdownMenuFooter = styled.div`
  display: flex;
  flex-direction: column;
  border-top: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
  cursor: default;
`;

export const DropdownMenuMessageTitle = styled.span`
  ${fontMd1};
`;

export const DropdownMenuMessageSubtitle = styled.span`
  ${deprecated.fontSm3};
`;

export const DropdownMenuItem = styled.li<{
  active: boolean;
  lastItemDivider: boolean;
  isOptionIndexDisabled: boolean;
}>`
  display: flex;
  flex-basis: 100%;
  padding: ${({ theme: { constants } }) => constants.spacerSm3}
    ${({ theme: { constants } }) => constants.spacerMd2};
  color: ${({ active, theme: { vars } }) =>
    active ? vars.accentPrimaryDefault : vars.textDefault};
  cursor: ${({ isOptionIndexDisabled }) => (isOptionIndexDisabled ? 'not-allowed' : 'pointer')};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  background-color: ${({ active, theme: { vars }, isOptionIndexDisabled }) => {
    if (isOptionIndexDisabled) {
      return vars.foundationBase2;
    } else if (active) {
      return `${vars.accentSubdued1} !important`;
    } else {
      return 'transparent';
    }
  }};

  &:last-child {
    border-top: ${({ lastItemDivider, theme: { constants, vars } }) =>
      lastItemDivider ? `${constants.borderWidthSm} solid ${vars.borderSurface2}` : 0};
  }

  &:hover {
    background-color: ${({ theme: { vars } }) => vars.foundationHover};
  }

  &:focus {
    outline: none;
  }
`;

export const DropdownMenuIconItem = styled(DropdownMenuItem)`
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  margin: ${({ theme: { constants } }) => constants.spacerSm2};
`;
