import { useCallback, useEffect, useState } from 'react';

import { useActiveMenu } from '../../contexts/ActiveMenuContext';
import { RegisteredMenuId } from '../../lib/idRegistry';

type Props = {
  // Use `null` for intentions of utilizing `setActiveMenuId`, `setNextMenu`, `closeMenu` when the menu is not known
  // `null` should not be used often
  menuId: RegisteredMenuId | null;
};

const useActiveMenuHandler = ({ menuId }: Props) => {
  const { activeMenuId, setActiveMenuId } = useActiveMenu();
  const [nextMenu, setNextMenu] = useState<RegisteredMenuId | null>(null);

  const isMenuOpen = menuId === activeMenuId;
  const closeMenu = () => setActiveMenuId(null);

  useEffect(() => {
    if (nextMenu && nextMenu !== activeMenuId) {
      setActiveMenuId(nextMenu);
      setNextMenu(null);
    }
  }, [activeMenuId, nextMenu, setActiveMenuId]);

  const handleMenuClick = useCallback(() => {
    setActiveMenuId(isMenuOpen ? null : menuId);
  }, [isMenuOpen, menuId, setActiveMenuId]);

  return {
    closeMenu,
    handleMenuClick,
    isMenuOpen,
    // setActiveMenuId should only be used in special cases (such as one menu opening another menu)
    setActiveMenuId,
    setNextMenu,
  };
};

export default useActiveMenuHandler;
