import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { useEditorContext } from '../../../../../../../contexts/EditorContext';
import { useEditorToolbarContext } from '../../../../../../../contexts/EditorToolbarContext';
import { RegisteredMenuId } from '../../../../../../../lib/idRegistry';
import initTranslations from '../../../../../../../lib/initTranslations';
import Flyout from '../../../../../../design_system/overlays/flyout';
import InputField from '../../../../../../design_system/Triage/InputField';
import useActiveMenuHandler from '../../../../../../design_system/useActiveMenuHandler';
import { FormGroup } from '../../../../../shared/FormGroup';
import ToolbarButton from '../ToolbarButton';

const t = initTranslations('editor.link');

const FormWrapper = styled.div`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
`;

const LinkToolbarButton = () => {
  const { editor } = useEditorContext();
  const { context } = useEditorToolbarContext();
  const menuId: RegisteredMenuId = `link-toolbar-flyout-${context}`;
  const { isMenuOpen, closeMenu } = useActiveMenuHandler({ menuId });

  const [url, setUrl] = useState('');
  const [text, setText] = useState('');
  const [primaryButtonDisable, setPrimaryButtonDisabled] = useState(false);

  useEffect(() => {
    setPrimaryButtonDisabled(!text.trim() || !url.trim());
  }, [text, url]);

  useEffect(() => {
    if (!isMenuOpen) {
      setText('');
      setUrl('');
    } else {
      setUrl(editor.getAttributes('link').href || '');
      editor
        .chain()
        .extendMarkRange('link')
        .command(
          ({
            tr: {
              selection: { from, to },
            },
            state,
          }) => {
            setText(state.doc.textBetween(from, to));
            return true;
          }
        )
        .run();
    }
  }, [editor, isMenuOpen]);

  const updateLinkInEditor = useCallback(() => {
    if (text && url) {
      let validUrl = url;
      if (validUrl && !validUrl.match(/^http[s]?:\/\//)) {
        validUrl = `http://${validUrl}`;
      }

      editor
        .chain()
        .focus()
        .insertContent({
          type: 'text',
          text,
          marks: [{ type: 'link', attrs: { href: validUrl } }],
        })
        .run();

      closeMenu();
    }
  }, [closeMenu, editor, text, url]);

  return (
    <Flyout
      className='link-flyout'
      id={menuId}
      placement='top'
      primaryButtonDisabled={primaryButtonDisable}
      primaryButtonTask={updateLinkInEditor}
      primaryButtonText={editor.isActive('link') ? t('edit_link_button') : t('add_link_button')}
      secondaryButtonTask={closeMenu}
      secondaryButtonText={t('cancel_button')}
      strategy='absolute'
      title={t('title')}
      triggerButton={
        <ToolbarButton
          active={editor.isActive('link')}
          ariaLabel={t('aria_label_link_button')}
          className={`flyout-trigger link-flyout-button ${
            editor.isActive('link') ? 'is-active' : ''
          }`}
          iconName='link-simple'
          id={`open-link-button-${context}`}
          tooltipId='hyperlink-tooltip'
          tooltipText={t('hyperlink')}
          weight='regular'
        />
      }
    >
      <FormWrapper>
        <FormGroup>
          <InputField
            id='link-text'
            label={t('link_text_label')}
            name='text'
            onChange={(e) => {
              setText(e.target.value);
            }}
            type='text'
            value={text}
          />
        </FormGroup>
        <FormGroup>
          <InputField
            id='link-url'
            label={t('link_url_label')}
            name='url'
            onChange={(e) => {
              setUrl(e.target.value);
            }}
            type='text'
            value={url}
          />
        </FormGroup>
      </FormWrapper>
    </Flyout>
  );
};

export default LinkToolbarButton;
