import React from 'react';

import { Direction } from '../../../../styled/FlexContainer';
import {
  DropdownMenuFooter,
  DropdownMenuIconItem,
  DropdownMenuItem,
  DropdownMenuList,
  DropdownMenuListWrapper,
  DropdownMenuMessage,
  DropdownMenuMessageSubtitle,
  DropdownMenuMessageTitle,
  DropdownMenuWrapper,
} from './styles';

export type MenuItemType = 'default' | 'icon';

export type MessageProps =
  | { messageTitle: string; messageSubtitle?: string }
  | { messageTitle?: never; messageSubtitle?: never };

const menuItemTypes = {
  default: DropdownMenuItem,
  icon: DropdownMenuIconItem,
};

export type Options = React.ReactNode[];

type DropdownMenuListProps = {
  activeOptionIndex: number | undefined;
  dropdownOpen: boolean;
  menuId: string;
  menuItemDirection: Direction;
  menuItemType: MenuItemType;
  options: Options;
  toggleDropdown: () => void;
  setSelectedOption: (indexOfSelectedOption: number) => void;
  lastItemDivider?: boolean;
  disabledOptionIndexes?: number[];
  footerElement?: React.ReactNode;
} & MessageProps;

const PoppableDropdownMenu = ({
  activeOptionIndex,
  dropdownOpen,
  menuId,
  menuItemDirection,
  menuItemType,
  options,
  toggleDropdown,
  setSelectedOption,
  lastItemDivider = false,
  messageTitle,
  messageSubtitle,
  disabledOptionIndexes = [],
  footerElement,
}: DropdownMenuListProps) => {
  const DropdownMenuItemComponent = menuItemTypes[menuItemType];

  return (
    <DropdownMenuWrapper>
      {dropdownOpen && (
        <>
          <DropdownMenuListWrapper>
            <DropdownMenuList
              direction={menuItemDirection}
              id={menuId}
              itemType={menuItemType}
              role='menu'
            >
              {messageTitle && (
                <DropdownMenuMessage>
                  <DropdownMenuMessageTitle>{messageTitle}</DropdownMenuMessageTitle>
                  {messageSubtitle && (
                    <DropdownMenuMessageSubtitle>{messageSubtitle}</DropdownMenuMessageSubtitle>
                  )}
                </DropdownMenuMessage>
              )}
              {options.map((option, index) => {
                return (
                  <DropdownMenuItemComponent
                    active={activeOptionIndex === index}
                    aria-current={activeOptionIndex === index}
                    isOptionIndexDisabled={disabledOptionIndexes.includes(index)}
                    key={`${index}-poppable-dropdown-item`}
                    lastItemDivider={lastItemDivider}
                    onClick={() => {
                      if (!disabledOptionIndexes.includes(index)) {
                        toggleDropdown();
                        setSelectedOption(index);
                      }
                    }}
                    role='menuitem'
                  >
                    {option}
                  </DropdownMenuItemComponent>
                );
              })}
            </DropdownMenuList>
          </DropdownMenuListWrapper>
          {!!footerElement && (
            <DropdownMenuFooter onClick={toggleDropdown}>{footerElement}</DropdownMenuFooter>
          )}
        </>
      )}
    </DropdownMenuWrapper>
  );
};

export default PoppableDropdownMenu;
