import { IconName } from '@fortawesome/fontawesome-svg-core';
import React, { useState } from 'react';
import { StrictModifier } from 'react-popper';
import styled, { css } from 'styled-components';

import useIdRegistry from '../../../../hooks/useIdRegistry';
import { MenuOptionRegisteredId, ThreeDotMenuOptionRegisteredId } from '../../../../lib/idRegistry';
import Poppable from '../../../Poppable';
import { fontSm5 } from '../../../styled/TypeSystem';
import Icon from '../../display/icons/Icon';
import { IconWeight } from '../../display/icons/Icon/IconTypes';
import Tooltip from '../../display/Tooltip/Tooltip';
import Hoverable from '../../Hoverable';
import P from '../../text/P';
import useActiveMenuHandler from '../../useActiveMenuHandler';
import { MenuOptions } from './shared';

const POPPER_OFFSET_MODIFIER: StrictModifier = {
  name: 'offset',
  options: {
    /* -16 is to counteract 1rem from GentleSpacing. */
    offset: [-16, 0],
  },
};

const POPPER_MODIFIERS_WITH_OFFSET: StrictModifier[] = [POPPER_OFFSET_MODIFIER];

const GentleSpacing = styled.div(
  ({ theme: { constants } }) => css`
    padding: ${constants.spacerMd2} 0 ${constants.spacerMd2} ${constants.spacerMd2};
  `
);

const StyledLi = styled.li<{ isDisabled?: boolean }>(
  ({ isDisabled, theme: { constants, vars } }) => css`
    display: block;
    color: ${isDisabled ? vars.textDisabled : vars.textDefault};
    font-weight: ${constants.fontRegular};
    padding: ${constants.spacerSm3} ${constants.spacerMd2};
    border-radius: ${constants.borderRadiusMd};

    &:hover {
      background: ${isDisabled ? vars.foundationSurface1 : vars.foundationHover};
      cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
    }

    &:active {
      background: ${isDisabled ? vars.foundationSurface1 : vars.foundationHover};
    }

    ${fontSm5};
  `
);

const OptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerMd1};
`;

const MenuOptionInner = styled.div`
  align-items: center;
  display: flex;
`;

const IconWrapper = styled.div(
  ({ theme: { constants } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1rem;
    height: ${constants.height2xs};
    margin-right: ${constants.spacerMd2};
  `
);

const Title = styled.span<{ hasDescription: boolean; isDisabled?: boolean }>`
  ${({ hasDescription, isDisabled, theme: { constants, vars } }) =>
    css`
      font-weight: ${hasDescription ? constants.fontMedium : constants.fontRegular};
      color: ${isDisabled ? vars.textDisabled : vars.textDefault};
    `};
`;

const StyledDescription = styled(P)<{ isDisabled?: boolean }>(
  ({ isDisabled, theme: { constants, vars } }) =>
    css`
      color: ${isDisabled ? vars.textDisabled : vars.textSubdued};
      margin-bottom: ${constants.spacerSm3};
      margin-left: ${constants.spacerLg1};
      white-space: initial;
      width: 14.875rem;
    `
);

type IconProps =
  | {
      customIcon?: never;
      iconName?: IconName;
      iconWeight?: IconWeight;
    }
  | {
      customIcon: React.ReactNode;
      iconName?: never;
      iconWeight?: never;
    };

export type MenuOptionProps = IconProps & {
  className?: string;
  description?: string;
  id: ThreeDotMenuOptionRegisteredId | MenuOptionRegisteredId;
  isDisabled?: boolean;
  onClick: ((e: React.MouseEvent) => void) | ((e: React.MouseEvent) => Promise<void>);
  title: string;
  tooltipText?: string;
  nestedOptions?: MenuOptionProps[];
};

const MenuOption = ({
  className,
  customIcon,
  description,
  iconName,
  iconWeight,
  id,
  isDisabled = false,
  nestedOptions,
  onClick,
  title,
  tooltipText,
}: MenuOptionProps) => {
  useIdRegistry(id);

  const { closeMenu } = useActiveMenuHandler({ menuId: null });
  const [isHovered, setIsHovered] = useState(false);
  const dataForTooltip = tooltipText ? `tooltip-action-${title}` : '';
  const onTrigger = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    !isDisabled && onClick(e);
    !isDisabled && closeMenu();
  };

  return (
    <>
      {tooltipText && <Tooltip id={dataForTooltip} text={tooltipText} />}
      <Hoverable setIsHovered={setIsHovered}>
        <Poppable
          isOpen={isHovered}
          item={
            <>
              {nestedOptions && (
                <div onClick={closeMenu}>
                  <GentleSpacing>
                    <MenuOptions className='nested-menu-options'>
                      {nestedOptions.map((option, index) => {
                        return (
                          <MenuOption
                            key={`nested-menu-option-${option.id}-${index}`}
                            {...option}
                            onClick={(e) => {
                              option.onClick(e);
                            }}
                          />
                        );
                      })}
                    </MenuOptions>
                  </GentleSpacing>
                </div>
              )}
            </>
          }
          modifiers={POPPER_MODIFIERS_WITH_OFFSET}
          onClick={() => {}}
          placement='left-start'
          trigger={
            <StyledLi
              className={className}
              data-for={dataForTooltip}
              data-is-disabled={isDisabled}
              data-tip
              id={id}
              isDisabled={isDisabled}
              onClick={onTrigger}
            >
              <OptionWrapper>
                <div>
                  <MenuOptionInner>
                    <IconWrapper>
                      {customIcon && customIcon}
                      {iconName && <Icon name={iconName} weight={iconWeight} />}
                    </IconWrapper>
                    <Title hasDescription={!!description} isDisabled={isDisabled}>
                      {title}
                    </Title>
                  </MenuOptionInner>
                  {description && <StyledDescription isDisabled={isDisabled} text={description} />}
                </div>
                {nestedOptions && nestedOptions.length > 0 && (
                  <Icon name='chevron-right' weight='regular' />
                )}
              </OptionWrapper>
            </StyledLi>
          }
        />
      </Hoverable>
    </>
  );
};

export default MenuOption;
