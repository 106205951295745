import { Level } from '@tiptap/extension-heading';
import React, { useCallback, useMemo } from 'react';
import styled, {
  DefaultTheme,
  FlattenSimpleInterpolation,
  StyledComponent,
  css,
  useTheme,
} from 'styled-components';

import { useEditorContext } from '../../../../../../../../contexts/EditorContext';
import useCurrentAccount from '../../../../../../../../hooks/useCurrentAccount';
import initTranslations from '../../../../../../../../lib/initTranslations';
import { ContentStyleGenericTag } from '../../../../../../../../types/ContentStyle';
import { paletteColorDecoder } from '../../../../../../../design_system/helpers';
import {
  fontLg1,
  fontLg2,
  fontMd1,
  fontMd3,
  fontMd5,
  fontSm4,
} from '../../../../../../../styled/TypeSystem';
import DropdownWithPoppableMenu from '../../../../../../shared/DropdownWithPoppableMenu';
import { DropdownWrapper } from '../../../../../../shared/DropdownWithPoppableMenu/styles';

const t = initTranslations('editor');

const DropdownText = styled.span`
  ${fontSm4};
`;

const OptionWrapper = styled.div`
  min-width: 13rem;
  padding-left: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const generateStyles = (
  styles: ContentStyleGenericTag | undefined,
  defaultStyle: FlattenSimpleInterpolation
) => {
  if (!styles) return defaultStyle;

  const { color, fontSize, fontStyle, textAlign, fontFamily, fontWeight, textDecoration } = styles;

  return css`
    color: ${color};
    font-size: ${fontSize};
    font-style: ${fontStyle};
    text-align: ${textAlign};
    font-family: ${fontFamily};
    font-weight: ${fontWeight};
    text-decoration: ${textDecoration};
  `;
};

const createStyledComponent = (defaultStyle: FlattenSimpleInterpolation) => {
  return styled.div<{ styles: ContentStyleGenericTag }>`
    ${({ styles }) => generateStyles(styles, defaultStyle)}
  `;
};

const Header1 = createStyledComponent(fontLg2);
const Header2 = createStyledComponent(fontLg1);
const Header3 = createStyledComponent(fontMd5);
const Header4 = createStyledComponent(fontMd3);
const NormalText = createStyledComponent(fontMd1);

const NORMAL_TEXT_INDEX = 4;

const HeadingDropdownButton = () => {
  const {
    lockBrandStyles,
    contentStyles: { h1, h2, h3, h4, p },
  } = useCurrentAccount();
  const { editor } = useEditorContext();
  const { palettes } = useTheme();

  const headingOptions = useMemo<
    {
      text: string;
      styledOption: StyledComponent<
        'div',
        DefaultTheme,
        { styles: ContentStyleGenericTag | undefined }
      >;
      styles: ContentStyleGenericTag | undefined;
      level?: Level;
    }[]
  >(() => {
    return [
      {
        text: t('heading.header_1'),
        styledOption: Header1,
        styles: h1,
        level: 1,
      },
      {
        text: t('heading.header_2'),
        styledOption: Header2,
        styles: h2,
        level: 2,
      },
      {
        text: t('heading.header_3'),
        styledOption: Header3,
        styles: h3,
        level: 3,
      },
      {
        text: t('heading.header_4'),
        styledOption: Header4,
        styles: h4,
        level: 4,
      },
      {
        text: t('heading.normal_text'),
        styledOption: NormalText,
        styles: p,
      },
    ];
  }, [h1, h2, h3, h4, p]);

  const options = useMemo(() => {
    // Fetch the active heading level from the editor
    const activeLevel = editor.getAttributes('heading').level;

    // Determine the active index based on the active heading level,
    // defaulting to normal text if no level is active.
    let activeIndex = activeLevel ? activeLevel - 1 : NORMAL_TEXT_INDEX;

    // Validate the active index and default to normal text is unsupported text is selected.
    if (!headingOptions[activeIndex]) {
      activeIndex = NORMAL_TEXT_INDEX;
    }

    // Fetch the selected option based on the active index.
    const selectedOption = <DropdownText>{headingOptions[activeIndex].text}</DropdownText>;

    return { activeIndex, selectedOption };
  }, [editor, headingOptions]);

  const { selectedOption, activeIndex } = options;

  const setSelectedOption = useCallback(
    (index: number) => {
      const selectedLevel = headingOptions[index].level;
      if (!selectedLevel) {
        editor.chain().focus().setParagraph().run();
      } else {
        editor.chain().focus().setHeading({ level: selectedLevel }).run();
      }
    },
    [editor, headingOptions]
  );

  const decodedStyles = useCallback(
    (styles: ContentStyleGenericTag | undefined) => {
      if (!styles) return styles;

      if (!!styles.color) {
        const decodedColor = paletteColorDecoder(palettes, styles.color);

        return { ...styles, color: decodedColor };
      }

      return styles;
    },
    [palettes]
  );

  return (
    <DropdownWrapper>
      <DropdownWithPoppableMenu
        activeOptionIndex={activeIndex}
        dataTestId='heading-dropdown'
        delayTooltipShow={250}
        id='heading-dropdown'
        menuId='heading-menu'
        messageSubtitle={lockBrandStyles ? t('heading.locked_styles_subtitle') : undefined}
        messageTitle={lockBrandStyles ? t('heading.locked_styles_title') : ''}
        options={headingOptions.map((item, index) => (
          <OptionWrapper key={`option-${index}`}>
            <item.styledOption styles={decodedStyles(item.styles)}>{item.text}</item.styledOption>
          </OptionWrapper>
        ))}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        style='toolbar'
        tooltipId='heading-tooltip'
        tooltipPlace='bottom'
        tooltipText={t('heading.styles')}
      />
    </DropdownWrapper>
  );
};

export default HeadingDropdownButton;
