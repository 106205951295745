import { PublishState } from '../redux/services/resourceApis/curriculums/types';
import { Course, CourseType, CourseWithSteps, CourseWithStepsCount } from './Course';
import { Flowchart } from './Flowchart';
import { Survey } from './Survey';
import { Video } from './Video';

export type CurriculumElementStatus = 'draft' | 'pending_review' | 'finished';

export type ElementType = 'Course' | 'Survey::Survey' | 'Flowchart' | 'Video';

export const elementKinds = ['course', 'survey', 'flowchart', 'video'] as const;
export type ElementKind = (typeof elementKinds)[number];

export const ELEMENT_TYPE_TO_KIND: { [key in ElementType]: ElementKind } = {
  Course: 'course',
  'Survey::Survey': 'survey',
  Flowchart: 'flowchart',
  Video: 'video',
};

type BaseCurriculumElement = {
  id: number;
  elementId: number;
  position: number;
  disableTranslation: boolean;
};

export type SurveyCurriculumElement = BaseCurriculumElement & {
  elementKind: 'survey';
  element: Survey;
};

type BaseCourseCurriculumElement<T extends CourseType> = BaseCurriculumElement & {
  elementKind: 'course';
  element: T;
};

export type FlowchartCurriculumElement = BaseCurriculumElement & {
  elementKind: 'flowchart';
  element: Omit<Flowchart, 'content'>;
};

type VideoCurriculumElement = BaseCurriculumElement & {
  elementKind: 'video';
  element: Video;
};

export type CourseCurriculumElement = BaseCourseCurriculumElement<Course>;
type CourseCurriculumElementWithStepsCount = BaseCourseCurriculumElement<CourseWithStepsCount>;
type CourseWithStepsCurriculumElement = BaseCourseCurriculumElement<CourseWithSteps>;
type CourseCurriculumElementVariants = CourseCurriculumElement | CourseWithStepsCurriculumElement;

export type CurriculumElement<
  CourseCurriculumElementType extends CourseCurriculumElementVariants = CourseCurriculumElement
> =
  | SurveyCurriculumElement
  | CourseCurriculumElementType
  | FlowchartCurriculumElement
  | VideoCurriculumElement;

export type EditableCurriculumElement = EditableCurriculumElementBase &
  CurriculumElement<CourseCurriculumElementWithStepsCount>;

export type EditableCourseCurriculumElement = EditableCurriculumElementBase &
  CourseCurriculumElementWithStepsCount;

export type EditableCurriculumElementBase = {
  canBeModified: boolean;
  eSignatureDisplayedAndRequired: boolean;
};

export type CurriculumElementState = {
  curriculumElements: EditableCurriculumElement[];
  publishState: PublishState;
};
