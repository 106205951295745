import styled, { css } from 'styled-components';

export const MenuOptions = styled.ul(
  ({ theme: { constants, vars } }) => css`
    background: ${vars.foundationSurface1};
    list-style: none;
    white-space: nowrap;
    overflow: hidden;
    padding: ${constants.spacerSm3};
    z-index: 10;
    box-shadow: ${vars.shadowTopSmall};
    border-radius: ${constants.borderRadiusLg};
    border: ${constants.borderWidthSm} solid ${vars.borderSurface2};
    margin: 0;
  `
);
