import React from 'react';

import ColorPickerIcon from './ColorPickerIcon';
import { ColorPickerIconButton, ColorPickerShapeIcon, ColorPickerTextIcon } from './styles';
import { ColorPickerTriggerProps } from './types';

const ColorPickerTrigger = ({
  ariaLabel,
  color,
  type,
  buttonId,
  active,
}: ColorPickerTriggerProps) => {
  const commonProps = { 'aria-label': ariaLabel, id: buttonId, active };

  const IconWrapper =
    type === 'background-color'
      ? ColorPickerIconButton
      : type === 'shape-color'
      ? ColorPickerShapeIcon
      : ColorPickerTextIcon;

  return (
    <IconWrapper data-testid='color-picker-trigger' {...commonProps}>
      <ColorPickerIcon color={color} type={type} />
    </IconWrapper>
  );
};

export default ColorPickerTrigger;
