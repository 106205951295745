import React from 'react';
import { useTheme } from 'styled-components';

import initTranslations from '../../../../lib/initTranslations';
import Poppable from '../../../Poppable';
import DefaultButton from '../../buttons/DefaultButton';
import OutsideClickHandler from '../../OutsideClickHandler';
import useActiveMenuHandler from '../../useActiveMenuHandler';
import {
  CloseIcon,
  FlyoutContainer,
  FlyoutContent,
  FlyoutFooter,
  FlyoutHeader,
  FlyoutHeaderTitle,
  FlyoutPoppableContainer,
  FlyoutPrimaryButton,
} from './FlyoutStyles';
import { FlyoutProps } from './FlyoutTypes';

const ariaTranslate = initTranslations('aria');

const Flyout = ({
  headerBackgroundColor,
  headerTextColor,
  id,
  title,
  children,
  className = 'flyout',
  primaryButtonDisabled,
  primaryButtonTask,
  primaryButtonText,
  fullWidthButtons = false,
  secondaryButtonTask,
  secondaryButtonText,
  strategy,
  triggerButton,
  placement,
  menuStyling = {},
  loading,
  onClose,
  scrollable = false,
  hideHeader,
}: FlyoutProps) => {
  const { closeMenu, handleMenuClick, isMenuOpen } = useActiveMenuHandler({
    menuId: id,
  });
  const { constants } = useTheme();
  if (!menuStyling.top) {
    menuStyling.top = constants.spacerMd2;
  }

  const showFooter = secondaryButtonText || primaryButtonText;

  return (
    <FlyoutPoppableContainer>
      <Poppable
        isOpen={isMenuOpen}
        item={
          <OutsideClickHandler
            onOutsideClick={(e) => {
              if (!(e.target as Element).classList.contains('flyout-trigger')) {
                onClose && onClose();
                handleMenuClick();
              }
            }}
          >
            <FlyoutContainer className={className} data-testid='flyout-container'>
              {!hideHeader && (
                <FlyoutHeader
                  className='flyout-header'
                  headerBackgroundColor={headerBackgroundColor}
                >
                  <FlyoutHeaderTitle headerTextColor={headerTextColor}>{title}</FlyoutHeaderTitle>
                  <CloseIcon
                    ariaLabel={ariaTranslate('aria_label_close')}
                    name='times'
                    onClick={() => {
                      onClose && onClose();
                      closeMenu();
                    }}
                  />
                </FlyoutHeader>
              )}
              <FlyoutContent className='flyout-content' scrollable={scrollable}>
                {children}
              </FlyoutContent>
              {showFooter && (
                <FlyoutFooter className='flyout-footer' fullWidthButtons={fullWidthButtons}>
                  {secondaryButtonText && secondaryButtonTask && (
                    <DefaultButton
                      buttonType='secondary'
                      fullWidth={fullWidthButtons}
                      id='flyout-secondary-button'
                      onClick={() => !loading && secondaryButtonTask()}
                      text={secondaryButtonText}
                    />
                  )}
                  {primaryButtonText && primaryButtonTask && (
                    <FlyoutPrimaryButton
                      buttonType='primary'
                      dataTestid='flyout-primary-button'
                      disabled={primaryButtonDisabled}
                      fullWidth={fullWidthButtons}
                      id='flyout-primary-button'
                      loading={loading}
                      onClick={primaryButtonTask}
                      text={primaryButtonText}
                    />
                  )}
                </FlyoutFooter>
              )}
            </FlyoutContainer>
          </OutsideClickHandler>
        }
        menuStyling={menuStyling}
        onClick={handleMenuClick}
        placement={placement}
        strategy={strategy}
        trigger={triggerButton}
      />
    </FlyoutPoppableContainer>
  );
};

export default Flyout;
