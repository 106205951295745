import { IconName } from '@fortawesome/fontawesome-svg-core';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  MutationDefinition,
} from '@reduxjs/toolkit/dist/query';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { Route } from 'type-route';

import { useAccountTerminology } from '../components/AccountTerminologyProvider';
import { routes } from '../components/application/publicApplication/applicationRouter';
import initTranslations from '../lib/initTranslations';
import { useUpdateTopicMutation } from '../redux/services/resourceApis/courses/topicsApi';
import { useUpdateFlowchartMutation } from '../redux/services/resourceApis/flowcharts/flowchartsApi';
import { UpdateFlowchartParams } from '../redux/services/resourceApis/flowcharts/types';
import { UpdateStepParams } from '../redux/services/resourceApis/steps/types';
import { useUpdateSurveyMutation } from '../redux/services/resourceApis/surveys/surveysApi';
import { UpdateSurveyParams } from '../redux/services/resourceApis/surveys/types';
import { UpdateVideoParams } from '../redux/services/resourceApis/videos/types';
import { useUpdateVideoMutation } from '../redux/services/resourceApis/videos/videosApi';
import { ELEMENT_TYPE_TO_KIND, ElementKind, ElementType } from '../types/CurriculumElement';
import useCurrentAccount from './useCurrentAccount';

const t = initTranslations('curriculum_elements');

type BaseArgs =
  | { elementKind: ElementKind; elementType?: never }
  | { elementKind?: never; elementType: ElementType };

type BaseElementAttributes = {
  termSingular: string;
  termPlural: string;
  iconName: IconName;
};

type RouteArgs = BaseArgs & {
  elementId: number;
};

type CurriculumElementMutationHook = UseMutation<
  MutationDefinition<
    UpdateVideoParams | UpdateFlowchartParams | UpdateSurveyParams | UpdateStepParams,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
    'CurriculumElement',
    undefined,
    'trainualApi'
  >
>;

type DetailedElementAttributes = BaseElementAttributes & {
  editRoute: Route<typeof routes>;
  showRoute: Route<typeof routes>;
  useUpdateElement: CurriculumElementMutationHook;
};

type CombinedArgs = BaseArgs & {
  elementId?: number;
};

const useCurriculumElement = () => {
  const { slug } = useCurrentAccount();
  const {
    topic: { singular: courseTermSingular, plural: courseTermPlural },
  } = useAccountTerminology();

  function elementAttributes({ elementKind, elementType }: BaseArgs): BaseElementAttributes;
  function elementAttributes({
    elementKind,
    elementType,
    elementId,
  }: RouteArgs): DetailedElementAttributes;
  function elementAttributes({
    elementKind,
    elementType,
    elementId,
  }: CombinedArgs): BaseElementAttributes | DetailedElementAttributes {
    const kind = elementKind || ELEMENT_TYPE_TO_KIND[elementType];

    switch (kind) {
      case 'course': {
        const baseAttributes: BaseElementAttributes = {
          termSingular: courseTermSingular,
          termPlural: courseTermPlural,
          iconName: 'file-lines',
        };

        if (elementId === undefined) return baseAttributes;

        return {
          ...baseAttributes,
          editRoute: routes.courseEditor({ slug, id: elementId }),
          showRoute: routes.courseConsume({ slug, id: elementId }),
          useUpdateElement: useUpdateTopicMutation,
        };
      }
      case 'survey': {
        const baseAttributes: BaseElementAttributes = {
          termSingular: t('survey'),
          termPlural: t('surveys'),
          iconName: 'clipboard-check',
        };

        if (elementId === undefined) return baseAttributes;

        return {
          ...baseAttributes,
          editRoute: routes.surveyEditor({ slug, id: elementId }),
          showRoute: routes.surveyConsume({ slug, id: elementId }),
          useUpdateElement: useUpdateSurveyMutation,
        };
      }
      case 'flowchart': {
        const baseAttributes: BaseElementAttributes = {
          termSingular: t('flowchart'),
          termPlural: t('flowcharts'),
          iconName: 'shapes',
        };

        if (elementId === undefined) return baseAttributes;

        return {
          ...baseAttributes,
          editRoute: routes.flowchartEditor({ slug, id: elementId }),
          showRoute: routes.flowchartConsume({ slug, id: elementId }),
          useUpdateElement: useUpdateFlowchartMutation,
        };
      }
      case 'video': {
        const baseAttributes: BaseElementAttributes = {
          termSingular: t('video'),
          termPlural: t('videos'),
          iconName: 'video',
        };

        if (elementId === undefined) return baseAttributes;

        return {
          ...baseAttributes,
          editRoute: routes.videoEdit({ slug, id: elementId }),
          showRoute: routes.videoConsume({ slug, id: elementId }),
          useUpdateElement: useUpdateVideoMutation,
        };
      }
    }
  }

  return elementAttributes;
};

export default useCurriculumElement;
