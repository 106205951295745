import React from 'react';
import { useTheme } from 'styled-components';

import { useEditorContext } from '../../../../../../../contexts/EditorContext';
import initTranslations from '../../../../../../../lib/initTranslations';
import DefaultButton from '../../../../../../design_system/buttons/DefaultButton';
import ColorPickerTrigger from '../../../../../../design_system/buttons/DeprecatedColorPicker/ColorPickerTrigger';
import ColorPicker from '../../../../../../design_system/ColorPicker/ColorPicker';
import ColorPickerInput from '../../../../../../design_system/ColorPicker/ColorPickerInput';
import { TEXT_COLORS } from '../../../../../../design_system/ColorPicker/constants';
import { SwatchesWrapper } from '../../../../../../design_system/ColorPicker/Swatches/styles';
import TextSwatch from '../../../../../../design_system/ColorPicker/Swatches/TextSwatch';
import Tooltip from '../../../../../../design_system/display/Tooltip/Tooltip';
import { paletteColorDecoder } from '../../../../../../design_system/helpers';
import useActiveMenuHandler from '../../../../../../design_system/useActiveMenuHandler';
import { useEditorColorHandler } from '../../../../shared/useEditorColorHandler';

const MENU_ID = 'editor-text-color-picker';

const t = initTranslations('editor');

const TextColorToolbarButton = () => {
  const { editor } = useEditorContext();
  const { closeMenu } = useActiveMenuHandler({ menuId: MENU_ID });
  const {
    vars: { textDefault },
    palettes,
  } = useTheme();

  const textColor = editor.getAttributes('textStyle').color
    ? editor.getAttributes('textStyle').color
    : textDefault;

  const decodedTextColor = paletteColorDecoder(palettes, textColor);

  const { handleColorSet } = useEditorColorHandler('textStyle', (color) => {
    editor.chain().focus().setColor(color).run();
  });

  const handleColorChange = (color: string) => {
    handleColorSet(color);
    closeMenu();
  };

  return (
    <>
      <Tooltip
        delayShow={250}
        id='editor-text-color-tooltip'
        place='bottom'
        text={t('text_color')}
      />
      <ColorPicker
        flyoutId={MENU_ID}
        strategy='absolute'
        trigger={
          <div data-for='editor-text-color-tooltip' data-tip>
            <ColorPickerTrigger
              ariaLabel={t('aria_label_change_text_color_button')}
              buttonId='editor-text-color-button'
              color={decodedTextColor}
              type='text-color'
            />
          </div>
        }
      >
        <SwatchesWrapper rowCount={7}>
          {TEXT_COLORS.map((color) => {
            return (
              <TextSwatch
                activeColor={decodedTextColor}
                color={paletteColorDecoder(palettes, color)}
                key={color}
                onClick={() => handleColorChange(color)}
              />
            );
          })}
        </SwatchesWrapper>
        <ColorPickerInput
          color={decodedTextColor}
          onChange={(color: string) => handleColorChange(color)}
        />
        <DefaultButton
          buttonType='tertiary'
          fullWidth
          iconName='arrow-rotate-left'
          id='editor-text-color-removal-button'
          onClick={() => {
            editor.chain().focus().unsetColor().run();
            closeMenu();
          }}
          text={t('reset_to_default')}
        />
      </ColorPicker>
    </>
  );
};

export default TextColorToolbarButton;
