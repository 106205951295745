import styled from 'styled-components';

import MenuOption from '../../../../design_system/core/MenuOption/MenuOption';
import { MenuOptions } from '../../../../design_system/core/MenuOption/shared';

export const StyledMenuOptions = styled(MenuOptions)`
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
`;

// DS Override: changed icon and text color to match design
export const StyledMenuOption = styled(MenuOption)<{ active: boolean }>`
  background: ${({
    active,
    theme: {
      vars: { accentSubdued1 },
    },
  }) => active && accentSubdued1};

  svg,
  > span {
    color: ${({
      active,
      theme: {
        vars: { accentPrimaryDefault, textDefault },
      },
    }) => (active ? accentPrimaryDefault : textDefault)};
  }

  &:hover,
  &:active {
    background: ${({
      active,
      theme: {
        vars: { accentSubdued1, foundationHover },
      },
    }) => (active ? accentSubdued1 : foundationHover)};

    svg,
    > span {
      color: ${({
        active,
        theme: {
          vars: { accentPrimaryDefault, textDefault },
        },
      }) => (active ? accentPrimaryDefault : textDefault)};
    }
  }
`;
