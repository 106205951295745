import { deprecated, fontSm2, fontSm4, fontSm5 } from '../components/styled/TypeSystem';
import { Size } from '../types/design_system/Size';

export const getFontStyle = (size: Size) => {
  switch (size) {
    case '2xs':
      return fontSm2;
    case 'xs':
      return deprecated.fontSm3;
    case 'sm':
      return fontSm4;
    case 'md':
      return fontSm5;
    case 'lg':
      return deprecated.fontMd2;
    case 'xl':
      return deprecated.fontMd4;
    case '2xl':
      return deprecated.fontLg4;
  }
};
