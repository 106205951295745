import React from 'react';

import EditorImageFlyout from '../../../../plugins/Image/EditorImageFlyout';

const AddImageToolbarButton = () => {
  return (
    <EditorImageFlyout
      menuId='bio-image-flyout'
      menuItems={['upload']}
      placement='auto'
      showLabel={false}
      strategy='absolute'
    />
  );
};

export default AddImageToolbarButton;
