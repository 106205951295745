import { toCamelCase } from '../../../../lib/keyFormatConverter';
import { trainualApi } from '../../trainualService';
import { GetVideoResponse, UpdateVideoParams } from './types';

const videosApi = trainualApi.injectEndpoints({
  endpoints: (builder) => ({
    getVideo: builder.query<GetVideoResponse, number>({
      query: (id: number) => ({ url: `ajax/videos/${id}` }),
      providesTags: (result, _error, id) => {
        if (result) {
          return [
            { type: 'CurriculumElement', id: `Element-${id}` },
            { type: 'CurriculumElement', id: result.curriculumElement.id },
          ];
        }
        return [];
      },
      transformResponse: (response: GetVideoResponse) => {
        return toCamelCase(response);
      },
    }),
    updateVideo: builder.mutation<undefined, UpdateVideoParams>({
      query: ({ id, emoji, title, description }: UpdateVideoParams) => ({
        url: `ajax/videos/${id}`,
        method: 'PUT',
        body: { emoji, title, description },
      }),
      invalidatesTags: (_result, _error, { id }) => {
        return [{ type: 'CurriculumElement', id: `Element-${id}` }];
      },
    }),
    completeVideo: builder.mutation<undefined, number>({
      query: (id: number) => ({ url: `ajax/videos/${id}/complete`, method: 'PUT' }),
      invalidatesTags: (_result, _error, arg) => [
        { type: 'CurriculumElement', id: `Element-${arg}` },
        { type: 'Curriculum', id: 'LIST' },
      ],
    }),
  }),
});

export const { useGetVideoQuery, useUpdateVideoMutation, useCompleteVideoMutation } = videosApi;
