import { Editor } from '@tiptap/react';
import React, { ReactElement, ReactNode, useContext, useState } from 'react';

export type SaveState = 'saving' | 'saved' | 'no-changes';

type EditorContext = {
  editor: Editor;
  saveState: SaveState;
  setSaveState: (isSaving: SaveState) => void;
  isAILoading?: boolean;
  isEditingTitle: boolean;
  setIsEditingTitle: (isEditing: boolean) => void;
};

type EditorProps = {
  children: ReactNode;
  editor: Editor;
  isAILoading?: boolean;
};

const EditorContext = React.createContext({} as EditorContext);
export const useEditorContext = () => useContext(EditorContext);

const EditorProvider = ({ children, editor, isAILoading }: EditorProps): ReactElement => {
  const [saveState, setSaveState] = useState<SaveState>('no-changes');
  const [isEditingTitle, setIsEditingTitle] = useState(false);

  return (
    <EditorContext.Provider
      value={{
        editor,
        saveState,
        setSaveState,
        isAILoading,
        isEditingTitle,
        setIsEditingTitle,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
};

export { EditorProvider };
