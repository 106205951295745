import { BgColorsOutlined } from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components';

import { isColorWhite } from '../../../application/editor/shared/helpers';
import Icon from '../../../design_system/display/icons/Icon';
import { ColorPickerIconInner, ColorPickerTextIconInner, TextIcon } from './styles';
import { ColorPickerTriggerProps } from './types';

// DS OVERRIDE: Add border color for icon to indicate border when white
const ShapeColorIcon = styled(Icon)<{ color: string }>`
  border: ${({ theme: { vars, constants }, color }) =>
    isColorWhite(color) ? `${constants.borderWidthSm} solid ${vars.borderDefault}` : 'none'};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
`;

const ColorPickerIcon = ({
  type,
  color,
}: Pick<ColorPickerTriggerProps, 'color' | 'type'>): JSX.Element => {
  switch (type) {
    case 'background-color':
      return <ColorPickerIconInner color={color} />;
    case 'cell-bg-color':
      return <BgColorsOutlined rev={undefined} style={{ fontSize: '1rem' }} />;
    case 'shape-color':
      return (
        <ShapeColorIcon
          color={color}
          name='circle'
          secondaryColor={color}
          size='xs'
          weight='duotone'
        />
      );
    default:
      return (
        <>
          <TextIcon
            name={type === 'highlighter-color' ? 'highlighter' : 'a'}
            size='xs'
            weight='regular'
          />
          <ColorPickerTextIconInner color={color} />
        </>
      );
  }
};

export default ColorPickerIcon;
