import React, { useMemo } from 'react';

import CoreSelectField, { Props } from '../../../core/CoreSelectField/CoreSelectField';
import {
  DisabledFieldTooltipProps,
  LabelTooltipProps,
} from '../../../core/Labels/SharedLabelTypes';
import { Option } from '../../../core/SelectOption/types';

const SingleSelectStyles = {
  singleValue: (
    provided: object,
    state: {
      isDisabled: boolean;
      selectProps: {
        vars: { textDisabled: boolean; textDefault: string };
      };
    }
  ) => ({
    ...provided,
    color: state.isDisabled
      ? state.selectProps.vars.textDisabled
      : state.selectProps.vars.textDefault,
    marginLeft: 0,
    marginRight: 0,
  }),
};

export type SingleSelectProps = Props & {
  defaultValue: string | null;
  value: string | null;
  labelTruncate?: boolean;
  onNonDefaultSelected?: (value: string | null) => void;
};

const SingleSelectField = (props: SingleSelectProps) => {
  const { defaultValue, value, placeholder, onDefaultSelected, onNonDefaultSelected } = props;

  const options = props.useSourceBadge ? props.sourceBadgeOptions : props.options;

  const getDefaultOption = (options: Option[]) =>
    options.find((option) => option.value === defaultValue) ||
    (placeholder ? undefined : options[0]);

  const optionFromValue = useMemo(() => {
    return options.find((option) => option.value === value);
  }, [options, value]);

  const handleSelectChanged = (option: Option | null) => {
    if (!option) {
      onNonDefaultSelected && onNonDefaultSelected(null);
      return;
    }

    const { value } = option;

    if (value !== defaultValue) {
      onNonDefaultSelected && onNonDefaultSelected(value);
    } else {
      onDefaultSelected && onDefaultSelected();
    }
  };

  const {
    labelTooltipId,
    labelTooltipText,
    disabledFieldTooltipId,
    disabledFieldTooltipText,
    ...propsToPass
  } = props;
  const labelTooltipProps: LabelTooltipProps =
    labelTooltipId && labelTooltipText ? { labelTooltipId, labelTooltipText } : {};
  const fieldTooltipProps: DisabledFieldTooltipProps =
    disabledFieldTooltipId && disabledFieldTooltipText
      ? { disabledFieldTooltipId, disabledFieldTooltipText }
      : {};

  return (
    <CoreSelectField
      {...propsToPass}
      {...labelTooltipProps}
      {...fieldTooltipProps}
      componentStyles={SingleSelectStyles}
      getDefaultOption={getDefaultOption}
      handleSelectChanged={handleSelectChanged}
      value={optionFromValue}
    />
  );
};

export default SingleSelectField;
