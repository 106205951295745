import React, { useEffect } from 'react';

import { useEditorContext } from '../../../../../contexts/EditorContext';
import { useImageFlyoutContext } from '../../../../../contexts/ImageFlyoutContext';
import { RegisteredMenuId } from '../../../../../lib/idRegistry';
import initTranslations from '../../../../../lib/initTranslations';
import useActiveMenuHandler from '../../../../design_system/useActiveMenuHandler';
import { Placement, Strategy } from '../../../../Poppable';
import { insertEditorContent } from '../../lib/insertEditorContent';
import { ImageMenuItems, getImageFlyoutItems } from '../../shared/helpers';
import AddImageToolbarButton from '../../toolbar/buttons/components/insert/AddImageToolbarButton';
import ImageFlyout from './ImageFlyout';

export type Props = {
  showLabel?: boolean;
  placement?: Placement;
  menuId: RegisteredMenuId;
  menuItems: ImageMenuItems[];
  strategy?: Strategy;
};

const t = initTranslations('editor.image');

const EditorImageFlyout = ({ showLabel, placement, menuId, menuItems, strategy }: Props) => {
  const { uploadResult } = useImageFlyoutContext();
  const { isMenuOpen } = useActiveMenuHandler({ menuId });
  const { isSuccess, reset } = uploadResult;
  const { editor } = useEditorContext();

  useEffect(() => {
    if (isSuccess && uploadResult.data) {
      insertEditorContent({
        editor,
        content: { type: 'image', attrs: { src: uploadResult.data.link } },
      });
    }
  }, [editor, isSuccess, reset, uploadResult.data]);

  return (
    <ImageFlyout
      menuId={menuId}
      menuItems={getImageFlyoutItems(menuItems, t)}
      placement={placement}
      strategy={strategy}
      trigger={<AddImageToolbarButton active={isMenuOpen} showLabel={showLabel} />}
    />
  );
};

export default EditorImageFlyout;
