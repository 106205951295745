export type FieldErrors = { [key: string]: string[] };
type FormError = { data: { baseErrors: FieldErrors[] } };

export const formErrorsFromError = (error: unknown): FieldErrors | undefined => {
  const castedError = error as FormError;
  if ('data' in castedError) {
    const { baseErrors } = castedError.data;
    return baseErrors[0];
  } else {
    console.error('Unhandled error type:', castedError);
  }
};

type Error = { data: { baseErrors: string[]; message: string[] | null } } | { message: string };

export const messageFromError = (error: unknown | undefined): string[] | undefined => {
  if (!error) return undefined;

  let baseErrorsArray: string[] = [];
  const castedError = error as Error;

  if ('message' in castedError) {
    baseErrorsArray = [castedError.message];
  } else if ('data' in castedError) {
    const { baseErrors, message } = castedError.data;
    baseErrorsArray = message ? message : baseErrors;
  } else {
    console.error('Unhandled error type:', castedError);
  }

  return baseErrorsArray;
};
