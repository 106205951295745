import { Formik, FormikProps } from 'formik';
import React from 'react';
import styled, { css } from 'styled-components';

import { useLocalesContext } from '../../../contexts/LocalesContext';
import initTranslations from '../../../lib/initTranslations';
import { isColorWhite } from '../../application/editor/shared/helpers';
import { validateHexCodeSchema } from '../../application/editor/shared/validators';
import DefaultButton from '../buttons/DefaultButton';
import InputField, { Props as InputProps } from '../Triage/InputField';

const InputAndButtonContainer = styled.div<{
  color: string | undefined;
}>`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};
  .fa-circle {
    color: ${({ color, theme: { vars } }) => (!!color ? color : vars.textPlaceholder)};
    ${({ color, theme: { constants, vars } }) =>
      isColorWhite(color) &&
      css`
        border: ${constants.borderWidthLg} solid ${vars.borderDefault};
        border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
      `}
  }
`;

// DS override: Ensures button text does not wrap
const SelectionButton = styled(DefaultButton)<{ nonEnglishLocale: boolean }>`
  overflow: hidden;
  white-space: nowrap;
  min-width: ${({ nonEnglishLocale }) => (nonEnglishLocale ? '11.5rem' : '6.5rem')};
`;

const t = initTranslations('color_picker');

type Props = {
  color: string | undefined;
  onChange: (color: string) => void;
  selectionButtonText?: string;
  hasSelectionButton?: boolean;
};

const ColorPickerInput = ({
  color,
  onChange,
  selectionButtonText = t('use_color'),
  hasSelectionButton = true,
}: Props) => {
  const inputIconProps = (color: string | undefined): InputProps => ({
    iconName: color ? 'circle' : 'empty-set',
    iconSize: '2xs',
    iconWeight: color ? 'solid' : 'regular',
  });

  const handleSubmit = (values: { color: string }) => {
    if (!values.color) return;

    onChange(values.color);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: string | undefined) => void
  ) => {
    let newValue = e.target.value;
    if (!newValue.startsWith('#')) {
      newValue = `#${newValue}`;
    }
    setFieldValue('color', newValue);
  };

  const { nonEnglishLocale } = useLocalesContext();

  return (
    <Formik
      initialValues={{ color }}
      onSubmit={handleSubmit}
      validationSchema={validateHexCodeSchema}
    >
      {({ errors, handleSubmit, setFieldValue, values }: FormikProps<{ color: string }>) => (
        <InputAndButtonContainer color={!errors.color ? values.color : undefined}>
          <InputField
            {...inputIconProps(!errors.color ? values.color : undefined)}
            errorText={errors.color}
            name='color'
            onBlur={() => {
              if (!errors.color && !hasSelectionButton) {
                handleSubmit();
              }
            }}
            onChange={(e) => handleInputChange(e, setFieldValue)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleSubmit();
              }
            }}
            placeholder={t('placeholder')}
            value={values.color}
          />
          {hasSelectionButton && (
            <SelectionButton
              buttonType='secondary'
              disabled={!!errors.color || !values.color}
              id='color-picker-selection-button'
              nonEnglishLocale={nonEnglishLocale}
              onClick={() => handleSubmit()}
              text={selectionButtonText}
            />
          )}
        </InputAndButtonContainer>
      )}
    </Formik>
  );
};

export default ColorPickerInput;
